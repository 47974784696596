import React  from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  ReferenceInput,
  Create,
  Filter,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  BooleanField,
  FormDataConsumer,
  AutocompleteInput,
  NumberField,
  ImageField,
  ImageInput,
} from "react-admin";
import { required } from "react-admin";
import { Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

const validateMandatory = [required()];
const formatoBottiglia = [
  { id: "187.5", name: "Piccolo 187.5 ml" },
  { id: "250", name: "Chopin 250 ml" },
  { id: "330", name: "330 ml" },
  { id: "375", name: "Demi 375 ml" },
  { id: "500", name: "500 ml" },
  { id: "750", name: "Standard 750 ml" },
  { id: "1500", name: "Magnum 1500 ml" },
  { id: "3000", name: "Jéroboam 3000 ml" },
  { id: "4500", name: "Réhoboam 4500 ml" },
  { id: "6000", name: "Mathusalem 6000 ml" },
  { id: "9000", name: "Salmanazar 9000 ml" },
  { id: "12000", name: "Balthazar 12000 ml" },
  { id: "15000", name: "Nabuchodonosor 15000 ml" },
  { id: "18000", name: "Meclhior 18000 ml" },
  { id: "20000", name: "Solomon 20000 ml" },
  { id: "25000", name: "Sovereign 25000 ml" },
  { id: "27000", name: "Primat  27000 ml" },
  { id: "30000", name: "Melchizédec 30000 ml" },
];

function getMoltiplicatore(costo) {
  if (costo <= 5) {
    return 0;
  } else if (costo > 5 && costo <= 10) {
    return 3.6;
  } else if (costo > 10 && costo <= 15) {
    return 3.4;
  } else if (costo > 15 && costo <= 20) {
    return 3.2;
  } else if (costo > 20 && costo <= 25) {
    return 3.0;
  } else if (costo > 25 && costo <= 30) {
    return 2.8;
  } else if (costo > 30 && costo <= 35) {
    return 2.6;
  } else {
    return 2.4;
  }
}

function calcolaPrezzo(costo) {
  if (costo <= 5) {
    return 20;
  }
  const prezzoTotale = costo * getMoltiplicatore(costo)
  return prezzoTotale.toFixed(2);
}

//FILTRO VINI (RISOLVERE PROBLEMA CON PARAMETRO SOURCE, CERCA SOLO PER NOMEVINO)
const ViniFilter = (props) => (
  <Filter {...props}>
    <TextInput
      name="search"
      label="Cerca vino"
      source="q"
      resettable
      alwaysOn
    />
    <ReferenceInput
      name="filter_tipo"
      label="Tipo"
      source="IdTipoVino"
      reference="TipiVino"
    >
      <SelectInput optionText="NomeTipoVino" />
    </ReferenceInput>
    <ReferenceInput
      name="filter_produttore"
      label="Produttore"
      source="IdProduttore"
      reference="Produttori"
    >
      <SelectInput optionText="NomeProduttore" />
    </ReferenceInput>
  </Filter>
);
//funzione da correggere per fare vedere il nome vino + annata
//<FunctionField label="Vino" render={record => `${record.NomeCompleto}`} />
//LIST VINI
export const Vini = (props) => (
  <List
    title="Lista dei vini"
    filters={<ViniFilter />}
    {...props}
    sort={{ field: "NomeVino", order: "ASC" }}
    perPage={25}
    exporter={false}
  >
    <Datagrid rowClick="edit">
      <TextField label="Codice" source="Codice" />
      <TextField label="Vino" source="NomeCompleto" />
      <TextField source="NomeProduttore" label="Produttore" />
      <TextField source="NomeTipoVino" label="Tipo" />
      <TextField source="VitigniString" label="Vitigni" />
      <NumberField
        source="Costo"
        label="Costo"
        options={{
          maximumFractionDigits: 2,
          style: "currency",
          currency: "EUR",
        }}
      />

      <NumberField
        source="Prezzo"
        label="Prezzo"
        options={{
          maximumFractionDigits: 2,
          style: "currency",
          currency: "EUR",
        }}
      />
      <NumberField
        source="PrezzoCalice"
        label="Prezzo bottiglia a calice"
        options={{
          maximumFractionDigits: 2,
          style: "currency",
          currency: "EUR",
        }}
      />
      <NumberField source="QtaMinimaAlert" label="Soglia Alert" />
      <BooleanField source="VisibileSuCarta" label="Visibile" />
      <BooleanField source="VisibileInProposte" label="Proposte" />
      <EditButton />
    </Datagrid>
  </List>
);



const VinoForm = ({ formData, ...rest }) => {
  const form = useFormContext();

  return (
    <Grid container spacing={3} fullWidth>
      <Grid item xs={12} sm={6}>
        <TextInput
          fullWidth
          label="Vino"
          source="NomeVino"
          validate={validateMandatory}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReferenceInput
          source="IdTipoVino"
          label="Tipo"
          reference="TipiVino"
          validate={validateMandatory}
          sort={{ field: "NomeTipoVino", order: "ASC" }}
          perPage={50000}
        >
          <AutocompleteInput optionText="NomeTipoVino" fullWidth />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ReferenceInput
          source="IdProduttore"
          label="Produttore"
          reference="Produttori"
          validate={validateMandatory}
          sort={{ field: "NomeProduttore", order: "ASC" }}
          perPage={50000}
        >
          <AutocompleteInput
            optionText="NomeProduttore"
            source="NomeProduttore"
            reference="Produttori"
            fullWidth
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReferenceInput
          source="IdRegione"
          label="Territorio"
          reference="Regioni"
          validate={validateMandatory}
          sort={{ field: "NomeCompletoRegione", order: "ASC" }}
          perPage={50000}
        >
          <AutocompleteInput
            optionText="NomeCompletoRegione"
            source="NomeCompletoRegione"
            reference="Regioni"
            fullWidth
          />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12}>
        <TextInput fullWidth source="Note" label="Note" resettable multiline />
      </Grid>

      <Grid item xs={12}>
        <ReferenceArrayInput
          source="AbbinamentoIds"
          label="Abbinamenti"
          reference="Abbinamenti"
          sort={{ field: "NomeAbbinamento", order: "ASC" }}
          perPage={50000}
        >
          <SelectArrayInput optionText="NomeAbbinamento" fullWidth />
        </ReferenceArrayInput>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="Annata" label="Annata" resettable fullWidth />
      </Grid>

      <Grid item xs={12} sm={6}>
        <NumberInput
          label="Grado Alcolico %"
          source="GradoAlcolico"
          inputProps={{
            step: 1,
            min: 1,
            max: 100,
            placeholder: "Vol %",
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SelectInput
          source="Volume"
          initialValue="750"
          choices={formatoBottiglia}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ReferenceArrayInput
          source="VitignoIds"
          reference="Vitigni"
          label="Vitigni"
          sort={{ field: "NomeVitigno", order: "ASC" }}
          perPage={50000}
        >
          <SelectArrayInput optionText="NomeVitigno" fullWidth />
        </ReferenceArrayInput>
      </Grid>

      <Grid item xs={6}>
        <BooleanInput label="Visibile su carta" source="VisibileSuCarta" />
      </Grid>
      <Grid item xs={6} sm={6}>
        <BooleanInput
          label="Visibile in proposte"
          source="VisibileInProposte"
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <NumberInput
              label="Costo"
              source="Costo"
              fullWidth
              xs={3}
              validate={validateMandatory}
              inputProps={{
                placeholder: "€",
                min: 0,
              }}
              onChange={(e) => {
                const value = e.target.value;
                form.setValue("CoefficientePrezzo", getMoltiplicatore(value));
                form.setValue("Costo", value);

                form.watch("Prezzo")
                form.setValue("Prezzo", calcolaPrezzo(value));
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <NumberInput
              disabled
              fullWidth
              xs={3}
              label="Moltiplicatore"
              source="CoefficientePrezzo"
              inputProps={{
                min: 0,
              }}
            />
          </Grid>
          <Grid item xs={6}>

                <NumberInput
                  fullWidth
                  xs={6}
                  label="Prezzo"
                  source='Prezzo'
                  validate={validateMandatory}
                  locales="it-IT"
                  options={{style:'currency', currency: 'EUR'}}
                />
            
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={6}>
        <BooleanInput
          label="Vendita bottiglia al calice"
          source="VenditaACalice"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.VenditaACalice && (
              <NumberInput
                fullWidth
                label="Prezzo bottiglia a calice"
                source="PrezzoCalice"
                inputProps={{
                  step: 1,
                  min: 0,
                  placeholder: "€",
                }}
              />
            )
          }
        </FormDataConsumer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          label="Codice"
          source="Codice"
          helperText="Codice personale che identifica una bottiglia"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <NumberInput
          label="Soglia Alert"
          source="QtaMinimaAlert"
          helperText="Nr. di bottiglie sotto la quale si riceve un alert"
          validate={validateMandatory}
          inputProps={{
            min: 1,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.IdVino === undefined && (
              <NumberInput
                name="qtaToAdd"
                label="QTA Iniziale Cantina"
                source="QtaToAdd"
                validate={validateMandatory}
                inputProps={{
                  min: 1,
                  step: 1,
                }}
              />
            )
          }
        </FormDataConsumer>
      </Grid>

      <Grid item xs={12} sm={3}>
        <ImageField source="ImageBase64" label="Immagine attuale" />
        <ImageInput
          source="pictures"
          label="Immagine rappresentativa"
          accept="image/*"
        >
          <ImageField source="ImageBase64" title="title" />
        </ImageInput>
      </Grid>
    </Grid>
  );
};

export const EditVino = (props) => (
  <Edit title="Modifica Vino" undoable={false} {...props}>
    <SimpleForm submitOnEnter={true} redirect="list" undoable={false}>
      <FormDataConsumer>
        {(formDataProps) => <VinoForm {...formDataProps} />}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
export const CreateVino = (props) => {
  return (
    <Create title="Aggiungi Vino" {...props}>
      <SimpleForm submitOnEnter={true} redirect="list" undoable={false}>
        <FormDataConsumer>
          {(formDataProps) => <VinoForm {...formDataProps} />}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default Vini;
