import React, { useState } from "react";
//import Paper from '@material-ui/core/Paper';
import { useGetList, Loading, Error } from "react-admin";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Card,
  Link,
  Drawer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CardIconFA } from "./CardIcon";
import AddIcon from "@material-ui/icons/Add";
import { faWineBottle } from "@fortawesome/free-solid-svg-icons";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";

const mainStyle = { flex: "1", marginRight: "1em", marginTop: 20 };
const cardStyle = {
  overflow: "inherit",
  textAlign: "right",
  padding: 16,
  minHeight: 106,
  borderRadius: 3,
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
};
const alertRedStyle = {
  borderLeftColor: red[500],
  borderLeftWidth: 5,
  borderLeftStyle: "solid",
};
const alertOrangeStyle = {
  borderLeftColor: orange[500],
  borderLeftWidth: 5,
  borderLeftStyle: "solid",
};

// const styles = {
//   main: {
//     flex: "1",
//     marginRight: "1em",
//     marginTop: 20,
//   },
//   card: {
//     overflow: "inherit",
//     textAlign: "right",
//     padding: 16,
//     minHeight: 106,
//   },
//   alertRed: {
//     borderLeftColor: red[500],
//     borderLeftWidth: 5,
//     borderLeftStyle: "solid",
//   },
//   alertOrange: {
//     borderLeftColor: orange[500],
//     borderLeftWidth: 5,
//     borderLeftStyle: "solid",
//   },
// };

const payload = {
  pagination: { page: 1, perPage: 10 },
  sort: { field: "NomeVino", order: "ASC" },
};

export const ViniInEsaurimento = ({ classes, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  // const { data, total, loading, error } = useQuery({
  //     type: 'getList',
  //     resource: 'AlertVini',
  //     payload: {
  //         pagination: { page: 1, perPage: 10 },
  //         sort: { field: 'NomeVino', order: 'ASC' }
  //     }
  // });
  const {
    data: vini,
    total,
    loading,
    error,
  } = useGetList("AlertVini", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "NomeVino", order: "ASC" },
  });

  const bgColor = total > 0 ? "red" : "green";

  const toggleDrawer = (open) => (event) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (!vini) {
    return null;
  }

  return (
    <div style={cardStyle}>
      <CardIconFA Icon={faWineBottle} bgColor={bgColor} />
      <Typography color="textSecondary">
        <Link href="#" onClick={toggleDrawer(true)} variant="body2">
          In esaurimento
        </Link>
      </Typography>
      <Typography variant="h5" component="h2">
        {total}
      </Typography>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Vino</TableCell>
              <TableCell>Soglia</TableCell>
              <TableCell>Qta</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vini.map((row) => {
              const url = "#/ViniStock/" + row.IdVinoStock;
              return (
                <TableRow key={row.IdVinoStock}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={
                      row.QtaMagazzino > 0
                        ? alertOrangeStyle
                        : alertRedStyle
                    }
                  >
                    <Typography variant="subtitle1">{row.NomeVino}</Typography>
                    <Typography variant="subtitle2">
                      {row.NomeProduttore}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{row.QtaMinimaAlert}</TableCell>
                  <TableCell align="right">{row.QtaMagazzino}</TableCell>
                  <TableCell>
                    <Link href={url}>
                      <AddIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Drawer>
    </div>
  );
};

export default ViniInEsaurimento;

/*
export const ViniInEsaurimento = withStyles(styles)(({ classes, ...props }) => (
        <Query type="getList" resource="AlertVini" payload={payload}>
            {({ data, total, loading, error }) => {
                if (loading) { return <Loading />; }
                if (error) { return <Error />; }
            const classTableHead = total > 0 ? classes.tableHeadEsaurito : classes.tableHeadNonEsaurito;
                return (                    
                    <Paper className={classes.paper}>
                        <h3 className={classTableHead}>Vini in esaurimento: {total}</h3>
                        <AddNewOrderButton record={data} />
                        <Table size="small" stickyHeader >
                            <TableHead>
                                <TableRow >
                                    <TableCell>Vino</TableCell>                                    
                                    <TableCell>Qta Alert</TableCell>
                                    <TableCell>Qta</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(row => (
                                    <TableRow key={row.NomeVino}>
                                        <TableCell component="th" scope="row">                                            
                                            <Typography variant="subtitle1">{row.NomeVino}</Typography>
                                            <Typography variant="subtitle2">{row.NomeProduttore}</Typography>
                                        </TableCell>
                                        <TableCell align="right">{row.QtaMinimaAlert}</TableCell>
                                        <TableCell align="right">{row.QtaMagazzino}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                );           
            }}
        </Query>
));
*/
