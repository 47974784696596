import * as React from "react";
import { useGetList, Loading, Error } from "react-admin";
import { Card, Typography, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TrendingUp from "@material-ui/icons/TrendingUp";
import CardIcon from "../CardIcon";
import {
  ResponsiveContainer,
  //ComposedChart,
  BarChart,
  Bar,
  //Line,
  //Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const styles = {
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
};

export const VenditeAnnualiChart = withStyles(styles)(
  ({ classes, ...props }) => {
    const anno = props.anno;
    // const { data, total, loading, error } = useQuery({
    //     type: 'getList',
    //     resource: 'VenditeTotali',
    //     payload: {
    //         pagination: { page: 1, perPage: 12 },
    //         sort: { field: 'Mese', order: 'ASC' },

    //         filter: {
    //             anno: anno,
    //         },
    //     }
    // });
    const {
      data: vini,
      total,
      loading,
      error,
    } = useGetList("VenditeTotali", {
      pagination: { page: 1, perPage: 12 },
      sort: { field: "Mese", order: "ASC" },

      filter: {
        anno: anno,
      },
    });

    if (loading) {
      return <Loading />;
    }
    if (error) {
      return <Error />;
    }
    if (!vini) {
        return null
      }
    return (
      <div className={classes.main}  style={{ marginTop: 50 }}>
        <CardIcon Icon={TrendingUp} bgColor="#31708f" />
        <div className={classes.card} style={{ boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"}}>
          <Typography className={classes.title} color="textSecondary">
            Vendite Mensili
          </Typography>
          <Typography variant="h5" component="h2">
            {anno}
          </Typography>

          <Divider />
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              width={500}
              height={300}
              data={vini}
              scale="time"
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Mese" />
              <YAxis unit="€" />
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                formatter={(value) =>
                  new Intl.NumberFormat(undefined, {
                    style: "currency",
                    currency: "EUR",
                  }).format(value)
                }
              />
              <Legend />
              <Bar dataKey="TotaleCosto" fill="#e60400" />
              <Bar dataKey="TotalePrezzo" fill="#82ca9d" />
              <Bar dataKey="TotaleBottiglie" fill="#ff7300" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
);

/* export const VenditeAnnualiChart = withStyles(styles)(({ classes, ...props }) => (
   
    <Query type="getList" resource="VenditeTotali" payload={payload}  >
        {({ data, loading, error }) => {
            const anno = props.anno;           
            if (loading) { return <Loading />; }
            if (error) { return <Error />; }

           
            return (
                <div className={classes.main}>
                    <CardIcon Icon={TrendingUp} bgColor="#31708f" />
                    <Card className={classes.card}>
                        
                        
                            <Typography className={classes.title} color="textSecondary">Vendite Mensili</Typography>
                            <Typography variant="h5" component="h2">{anno}</Typography>
                        
                            <Divider />
                                <ResponsiveContainer width="100%" height={400}  >
                                <BarChart 
                                        width={500}
                                        height={300}
                                        data={data}
                                        margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="Mese" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar  dataKey="TotaleCosto"  fill="#e60400" />
                                        <Bar  dataKey="TotalePrezzo"  fill="#82ca9d" />
                                        <Bar  dataKey="TotaleBottiglie" fill="#ff7300" />
                                    </BarChart>
                                </ResponsiveContainer>
                            
                        
                    </Card>
                 </div>
            );
        }}
    </Query>
)); */

export default VenditeAnnualiChart;
