import * as React from "react";
import { FC, ComponentType } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  bgColor: string;
  Icon: ComponentType<SvgIconProps>;
}

const iconStyle = {
  float: "right",
  width: "80px",
  height: "80px",
  padding: 14,
  color: "#fff",
};
const iconFAStyle = {
  float: "right",
  width: 54,
  height: 54,
  padding: 14,
  color: "#fff",
};

const CardIcon: FC<Props> = ({ Icon, bgColor }) => {
  return (
    <div
      style={{
        backgroundColor: bgColor,
        float: "left",
        margin: "-45px 20px 0 15px",
        zIndex: 100,
        borderRadius: 3,
      }}
    >
      <Icon style={iconStyle} />
    </div>
  );
};

export const CardIconFA: FC<Props> = ({ Icon, bgColor }) => {
  // const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: bgColor,
        float: "left",
        margin: "-45px 20px 0 15px",
        zIndex: 100,
        borderRadius: 3,
      }}
    >
      <FontAwesomeIcon icon={Icon} style={iconFAStyle} size="3x" />
    </div>
  );
};

export default CardIcon;
