import React from 'react';
//import ReactDOM from 'react-dom';
import {
    List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    Create, Filter
} from 'react-admin';

//FILTRO
const AbbinamentiFilter = props => (
    <Filter {...props}>
        <TextInput
            name="search"
            label="Cerca"
            source="q"
            resettable
            alwaysOn />
    </Filter>
);
//LIST Abbinamenti
export const Abbinamenti = props => (
    <List
        title="Lista Abbinamenti"
        filters={<AbbinamentiFilter />} {...props}
        sort={{ field: 'NomeAbbinamento', order: 'ASC' }}
        perPage={25}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="NomeAbbinamento"
                label="Abbinamento"                
            />
            <TextField
                source="NomeTipoAbbinamento"
                label="Tipo"                
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const CreateAbbinamento = props => (
    <Create title="Aggiungi abbinamento" {...props}>
        <SimpleForm submitOnEnter={true} redirect="list"  undoable={false}>
            <ReferenceInput label="Tipo" source="IdTipoAbbinamento" reference="TipiAbbinamento">
                <SelectInput source="IdTipoAbbinamento" optionText="NomeTipoAbbinamento" />
            </ReferenceInput>
            <TextInput
                source="NomeAbbinamento"
                label="Nome del piatto"
                required={true}
                fullWidth={true}
                helperText="es: pasta alla carbonara, agnello arrosto, etc. MAX 50 caratteri"
                resettable />
            
        </SimpleForm>
    </Create>
);

export const EditAbbinamento = props => (
    <Edit title="Modifica Abbinamento"{...props}>
        <SimpleForm submitOnEnter={true}  undoable={false}>
            <ReferenceInput label="Tipo" source="IdTipoAbbinamento" reference="TipiAbbinamento"  required={true}>
                <SelectInput source="IdTipoAbbinamento" optionText="NomeTipoAbbinamento" />
            </ReferenceInput>
            <TextInput
                source="NomeAbbinamento"
                label="Nome del piatto"
                required={true}
                fullWidth={true}
                helperText="es: pasta alla carbonara, agnello arrosto, etc. MAX 50 caratteri"
                resettable />
            
        </SimpleForm>
    </Edit>
);

