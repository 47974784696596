import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Filter,
  NumberInput,
  BooleanInput,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  BooleanField,
  Pagination,
  NumberField,
  CreateButton,
  useRecordContext,
  useGetRecordId,
  useCreate,
  useRedirect,
  Link,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";


const styles = {
  tab: {
    maxWidth: "40em",
    display: "block",
  },
};


const useStyles = makeStyles(styles);

//FILTRO
const MenuTitoliFilter = (props) => (
  <Filter {...props}>
    <TextInput name="search" label="Cerca" source="q" resettable alwaysOn />
  </Filter>
);

//LIST REGIONI
export const MenuTitoli = (props) => {
 
  return(

  <List
    title="Menu Titoli"
    filters={<MenuTitoliFilter />}
    {...props}
    sort={{ field: "Ordinamento", order: "ASC" }}
    perPage={25}
  >
    <Datagrid >
      <TextField source="IdMenuTitolo" label="Id" />
      <TextField source="Titolo" label="Titolo" />
      <TextField source="Ordinamento" label="Ordinamento" />
      <BooleanField source="Visibile" label="Visibile" />
      <EditButton />
    </Datagrid>
  </List>
)};

//VALIDATE EDIT MENU TITOLO
const ValidateMenuTitoli = (values) => {
  const errors = {};
  if (!values.Titolo) {
    errors.Titolo = ["Campo obbligatorio"];
  }
  return errors;
};

//EDIT MENU TITOLO
export const EditMenuTitoli = (props) => {
  const classes = useStyles();
  const record = useRecordContext();
  const id = useGetRecordId();


  // console.log(props)
  return (
    <Edit title="Modifica Menu Titoli" {...props} undoable={false}>
      {/* <AddMenuButton idMenuTitolo={id}/> */}
      <TabbedForm
        submitOnEnter={true}
        redirect="list"
        validate={ValidateMenuTitoli}
      >
        <FormTab label="Titolo menu" contentClassName={classes.tab}>
          <TextInput source="Titolo" label="Titolo" required={true} />
          <NumberInput source="Ordinamento" label="Ordinamento" />
          <BooleanInput source="Visibile" label="Visibile" />
        </FormTab>
        <FormTab label="Voci menu" path="Menu">
          <ReferenceManyField
            reference="Menu"
            target="IdMenuTitolo"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <TextField source="SottoTitolo" label="Voce" />
              <TextField source="Descrizione" label="Descrizione" />
              <NumberField
                source="Prezzo"
                label="Prezzo"
                options={{
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "EUR",
                }}
              />
              <TextField source="Ordinamento" label="Ordinamento" />
              <BooleanField source="Visibile" label="Visibile" />
              {/* <EditButton /> */}
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const CreateMenuTitoli = (props) => {
  const [create] = useCreate()
  const redirect = useRedirect();
  const postData = (data) => {
    create('MenuTitolo', { data });
    redirect(`/MenuTitolo`)
};
  return(
  <Create title="Aggiungi Titolo" {...props} undoable={false}>
    <SimpleForm
      submitOnEnter={true}
      onSubmit={postData}
      validate={ValidateMenuTitoli}
    >
      <TextInput source="Titolo" label="Titolo" resettable required={true} />
      <NumberInput source="Ordinamento" label="Ordinamento" resettable />
      <BooleanInput source="Visibile" label="Visibile" />
    </SimpleForm>
  </Create>
)};

export default MenuTitoli;
