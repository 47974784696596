//import * as React from 'react';
import React, { useState } from "react";
import { useGetList, Loading, Error } from "react-admin";
import {
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TrendingUp from "@material-ui/icons/TrendingUp";
import CardIcon from "../CardIcon";
import { Sector } from "recharts";

const styles = {
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
};

//const COLORS = ['#ffb4b3', '#ff8280', '#ff4f4d', '#ff1d1a', '#e60400', '#b30300', '#800200', '#4d0100'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`TOT ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export const VenditeAnnualiTopTenChart = withStyles(styles)(
  ({ classes, ...props }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [anno, setAnno] = useState(props.anno);

    // const { data, total, loading, error } = useQuery({
    //     type: 'getList',
    //     resource: 'VenditeTotaliTopTen',
    //     payload: {
    //         pagination: { page: 1, perPage: 12 },
    //         sort: { field: 'Mese', order: 'ASC' },
    //         filter: {
    //             anno: anno,
    //         },
    //     }
    // });
    const {
      data: vini,
      total,
      loading,
      error,
    } = useGetList("VenditeTotaliTopTen", {
      pagination: { page: 1, perPage: 12 },
      sort: { field: "Mese", order: "ASC" },
      filter: {
        anno: anno,
      },
    });

    if (loading) {
      return <Loading />;
    }
    if (error) {
      return <Error />;
    }
    if (!vini) {
      return null;
    }

    /*const dataChart = data.map(row => (  
        {
            name: row.NomeVino,
            value: row.TotaleBottiglie
        }
    ))*/

    return (
      <div
        className={{ flex: "1", marginRight: "1em", marginTop: 20 }}
        style={{ marginTop: 30 }}
      >
        <CardIcon Icon={TrendingUp} bgColor="#31708f" />
        <div
          style={{
            overflow: "inherit",
            textAlign: "right",
            padding: 16,
            minHeight: 52,
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Typography className={classes.title} color="textSecondary">
            Vendite TOP 10 Vini
          </Typography>
          <Typography variant="h5" component="h2">
            {anno}
          </Typography>

          <Divider />
          <div style={{ width: "100%" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Vino</TableCell>
                  <TableCell>Qta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vini.map((row, index) => (
                  <TableRow key={row.IdVino}>
                    <TableCell align="left">
                      <Typography variant="subtitle1">{index + 1}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle1">
                        {row.NomeVino}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">{row.TotaleBottiglie}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
);

export default VenditeAnnualiTopTenChart;
