import React from 'react';
import { fetchUtils, Admin, Resource, Login, resolveBrowserLocale } from 'react-admin';

//import jsonServerProvider from 'ra-data-json-server';
import myDataProvider from './dataProvider';

import { Store, EuroSymbol, LocalFlorist, Terrain, People } from '@material-ui/icons';

import MyLayout from './MyLayout';
import Dashboard from './components/DashBoard/Dashboard';
import { TipiVino, EditTipiVino, CreateListaTipiVino } from './components/TipiVino';
import { Vini, EditVino, CreateVino } from './components/Vini';
import { ViniStock, CreateVinoStock, EditVinoStock } from './components/ViniStock';
import { Produttori, EditProduttore, CreateProduttore } from './components/Produttori';
import { Vitigni, EditVitigno, CreateVitigno } from './components/Vitigni';
import { Regioni, EditRegione, CreateRegione } from './components/Regioni';
import Stati, { EditStato, CreateStato } from './components/Stati';
import { ViniOrdini, CreateVinoOrdine } from './components/ViniOrdini';
import { VenditePerPeriodo } from './components/VenditePerPeriodo';
import { Abbinamenti, CreateAbbinamento, EditAbbinamento } from './components/Abbinamenti';

import { EditMenu, CreateMenu, Menu } from './components/Menu/Menu';
import { MenuTitoli, EditMenuTitoli, CreateMenuTitoli } from './components/Menu/MenuTitoli';
import { MenuCamatilde, EditMenuCamatilde, CreateMenuCamatilde } from './components/Menu/MenuCamatilde'

import authProvider from './authProvider';
import { API_URL } from './globalConst';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import italianMessages from 'ra-language-italian';

//import { change, submit, isSubmitting } from 'redux-form';

italianMessages.ra.page.empty = 'Nessun elemento trovato';
italianMessages.ra.page.invite = 'Vuoi crearne uno?';

const messages = {
    it: italianMessages,
    en: englishMessages,
};

//TRADUZIONE ITALIANO
//const i18nProvider = polyglotI18nProvider(() => italianMessages, 'it');
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale()
);

/*
//PER AUTENTICAZIONE
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', 'Bearer '+token);

    //console.log(options.headers);
    return fetchUtils.fetchJson(url, options);
}



export const dataProvider = jsonServerProvider(API_URL, httpClient);
*/

const MyLoginPage = () => <Login backgroundImage="./sfondo-carta-dei-vini.jpg" />;

const App = () => {
    
    return (

        <Admin title="Carta dei vini" locale="it" i18nProvider={i18nProvider} layout={MyLayout} dashboard={Dashboard} loginPage={MyLoginPage} dataProvider={myDataProvider} authProvider={authProvider}>
         {permissions => [   
            <Resource name="ViniStock" edit={EditVinoStock} create={CreateVinoStock} list={ViniStock} options={{ label: 'Cantina' }} />,
            <Resource name="VenditePerPeriodo" list={VenditePerPeriodo} icon={EuroSymbol} options={{ label: 'Vendite per periodo' }} />,
            <Resource name="ViniOrdini" list={ViniOrdini} icon={EuroSymbol} create={CreateVinoOrdine} options={{ label: 'Ordini' }} />,
            <Resource name="Vini" icon={Store} list={Vini} edit={EditVino} create={CreateVino} options={{ label: 'Vini' }} />,
            <Resource name="Abbinamenti" list={Abbinamenti} edit={EditAbbinamento} create={CreateAbbinamento} options={{ label: 'Abbinamenti piatti' }}/>,
            <Resource name="Produttori" icon={People} list={Produttori} edit={EditProduttore} create={CreateProduttore} options={{ label: 'Produttori' }} />,
            <Resource name="TipiVino" list={TipiVino} edit={EditTipiVino} create={CreateListaTipiVino} options={{ label: 'Tipi Vino' }} />,
            <Resource name="Vitigni" list={Vitigni} icon={LocalFlorist} edit={EditVitigno} create={CreateVitigno} options={{ label: 'Vitigni' }} />,
            <Resource name="Stati" list={Stati} icon={Terrain} edit={EditStato} create={CreateStato} options={{ label: 'Stati' }} />,
            <Resource name="Regioni" list={Regioni} icon={Terrain} edit={EditRegione} create={CreateRegione} options={{ label: 'Regioni' }} />,
            
           
            <Resource name="MenuCamatilde" list={permissions.customMenu === 'MenuCamatilde' ? MenuCamatilde : null} edit={EditMenuCamatilde} create={CreateMenuCamatilde} options={{ label: 'Menu Titoli' }} />,
            <Resource name="MenuTitolo"  list={permissions.customMenu ===  'MenuTitolo' ? MenuTitoli : null} edit={EditMenuTitoli} create={CreateMenuTitoli} options={{ label: 'Menu Titoli' }} />,
            <Resource name="Menu" list={Menu} edit={EditMenu} create={CreateMenu} options={{ label: 'Menu Voci' }} />,
            
            <Resource name="TipiAbbinamento"  />
        ]}
        </Admin>
    );
};

export default App;