//import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

import { END_POINT } from './globalConst';

export default {
    login: ({ username, password }) => {

        var dataParams = {
            username: username,
            password: password,
            grant_type: 'password'            
        };

        var formBody = [];
        for (var property in dataParams) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dataParams[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&")
        
        const request = new Request(END_POINT + "/Token", {
            method: 'POST',
            body: formBody,
            mode: 'cors',
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }),
        });
        
        return fetch(request)
            .then(r => r.json().then(data => ({ status: r.status, body: data })))
            .then(obj => {
                //console.log(obj);
                if (obj.status === 400) {
                    throw new Error("Username o password non validi");
                }
                if (obj.status < 200 || obj.status >= 300) {
                    throw new Error("Opsss si è verificato un errore");
                }

                localStorage.setItem('username', obj.body.userName);
                localStorage.setItem('role', obj.body.permission);
                localStorage.setItem('customMenu', obj.body.customMenu);
                localStorage.setItem('token', obj.body.access_token);
                localStorage.setItem('qrcode', obj.body.qrcode);
                return Promise.resolve();
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        localStorage.removeItem('qrcode');
        return Promise.resolve();
    },
    checkError: error => {
        const { status } = error;
        console.error("AUTH_ERROR", status);
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            localStorage.removeItem('qrcode');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const permissions = {
            role: localStorage.getItem('role'),
            customMenu: localStorage.getItem('customMenu'),
        };
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    }
};
/*
export default (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        
        const { username, password } = params;
       
        var dataParams = {
            userName: username,
            password: password,
            grant_type: 'password'
        };

        var formBody = [];
        for (var property in dataParams) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dataParams[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        var request = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: formBody
        };

        return fetch(END_POINT + "/Token", request)
                    .then(r => r.json().then(data => ({ status: r.status, body: data })))
            .then(obj => {
                //console.log(obj);
                if (obj.status === 400) {
                    throw new Error("Username o password non validi");
                }
                if (obj.status < 200 || obj.status >= 300) {
                    throw new Error("Opsss si è verificato un errore");
                }
                
                localStorage.setItem('username', obj.body.userName);
                localStorage.setItem('token', obj.body.access_token);
                return Promise.resolve();
            });

    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        return Promise.resolve();
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        
        const { status } = params;
        console.error("AUTH_ERROR", status);
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {        
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const username = localStorage.getItem('username');
        console.log("permission", username)
        return username ? Promise.resolve(username) : Promise.reject();
    }

    return Promise.reject('Unknown method');
};
*/