import * as React from "react";
//import { FC } from 'react';
import { useGetOne, Loading, Error } from "react-admin";

import Card from "@material-ui/core/Card";
//import Icon from '@material-ui/core/Icon';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWineBottle } from "@fortawesome/free-solid-svg-icons";

import EuroIcon from "@material-ui/icons/EuroSymbol";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ViniInEsaurimento } from "./ViniInEsaurimento";

//import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

//import { useTranslate } from 'react-admin';

import CardIcon, { CardIconFA } from "./CardIcon";

const rootStyle = {
  flexGrow: 1,
  marginBottom: 10,
  marginTop: 30,
  padding: 2
};

const mainStyle = {
  flex: "1",
  marginRight: "1em",
  marginTop: 40,
};
const titleStyle = {
  fontSize: "14px",
};

const cardStyle = {
  overflow: "inherit",
  textAlign: "right",
  padding: 16,
  minHeight: 106,
  borderRadius: 3,
  boxShadow : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
};

const TotaleCantina = () => {
  const {
    data: vini,
    loading,
    error,
  } = useGetOne("ViniStockTotali", { id: 1 });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (!vini) {
    return null;
  }

  return (
    <Grid container style={rootStyle} spacing={1}>
      <Grid item xs={12} md={3} >
        <div style={cardStyle}>
          <CardIconFA Icon={faWineBottle} bgColor="#31708f" />
          <Typography style={titleStyle} color="textSecondary">
            Bottiglie in Cantina
          </Typography>
          <Typography variant="h5" component="h2">
            {vini.TotaleBottiglie}
          </Typography>
        </div>

      </Grid>
       <Grid item xs={12} md={3}>
        <div style={cardStyle}>
          <CardIcon Icon={EuroIcon} bgColor="#31708f" /> 
            <Typography style={titleStyle} color="textSecondary">
              Costo Cantina
            </Typography>
            <Typography variant="h5" component="h2">
              {vini.TotaleCosto.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
              })}
            </Typography>
        </div >
      </Grid>
     <Grid item xs={12} md={3}>
        <div style={cardStyle}>
          <CardIcon Icon={EuroIcon} bgColor="#31708f" />
          
            <Typography style={titleStyle} color="textSecondary">
              Valore Cantina
            </Typography>
            <Typography variant="h5" component="h2">
              {vini.TotalePrezzo.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <ViniInEsaurimento />
      </Grid>
    </Grid>
  );

  //     <Query type="getOne" resource="ViniStockTotali" payload={{ id: 1}}>
  //         {({ vini, loading, error }) => {
  //             if (loading) { return <Loading />; }
  //             if (error) { return <Error />; }
  //             return (

  //                 <Grid container className={classes.root} spacing={2}>
  //                     <Grid item xs={12} sm={3} >
  //                         <div className={classes.main}>
  //                             <CardIconFA Icon={faWineBottle} bgColor="#31708f" />
  //                             <Card className={classes.card} >
  //                                 <Typography className={classes.title} color="textSecondary">Bottiglie in Cantina</Typography>
  //                                 <Typography variant="h5" component="h2">{data.TotaleBottiglie}</Typography>
  //                             </Card>
  //                         </div>
  //                     </Grid>
  //                     <Grid item xs={12} sm={3}>
  //                         <div className={classes.main}>
  //                             <CardIcon Icon={EuroIcon} bgColor="#31708f" />
  //                             <Card className={classes.card}>
  //                                 <Typography className={classes.title} color="textSecondary">Costo Cantina</Typography>
  //                                 <Typography variant="h5" component="h2">{data.TotaleCosto.toLocaleString('it-IT', { minimumFractionDigits: 2 })}</Typography>
  //                             </Card>
  //                         </div>
  //                     </Grid>
  //                     <Grid item xs={12} sm={3}>
  //                         <div className={classes.main}>
  //                             <CardIcon Icon={EuroIcon} bgColor="#31708f" />
  //                             <Card className={classes.card}>
  //                                 <Typography className={classes.title} color="textSecondary">Valore Cantina</Typography>
  //                                 <Typography variant="h5" component="h2">{data.TotalePrezzo.toLocaleString('it-IT', { minimumFractionDigits: 2 })}</Typography>
  //                             </Card>
  //                         </div>
  //                     </Grid>
  //                     <Grid item xs={12} sm={3}>
  //                         <ViniInEsaurimento />
  //                     </Grid>
  //                 </Grid>

  //             );
  //         }}
  //     </Query>
  // );
};
export default TotaleCantina;
