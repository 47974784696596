import React from 'react';
//import ReactDOM from 'react-dom';
import {
    List, Datagrid, TextField, NumberInput,
    EditButton, Edit, SimpleForm, TextInput, Create, Filter, 
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

// The List component supports the `root`, `header`, `actions` and `noResults` CSS classes. Here we override the `header` and `actions` classes
const listStyles = {
    actions: {
        backgroundColor: 'Lavender',
    },
    header: {
        backgroundColor: 'Lavender',
    },
};

//FILTRO
const TipoVinoFilter = props => (
    <Filter {...props}>
        <TextInput
            name="search"
            label="Cerca tipo"
            source="q"
            resettable
            alwaysOn />
    </Filter>
);

//LIST TIPI VINO
export const TipiVino = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        classes={classes} {...props}
        title="Lista tipi di vino"
        filters={<TipoVinoFilter />}
        sort={{ field: 'OrdineVisualizzazione', order: 'ASC' }}
        perPage={25}
    >
        <Datagrid>
            <TextField label="Ordinamento APP" source="OrdineVisualizzazione" />
            <TextField source="NomeTipoVino" label="Tipo" />
            <EditButton />
        </Datagrid>
    </List>
));
//VALIDATE EDIT TIPO
const ValidateEditTipoVino = (values) => {
    const errors = {};
    if (!values.NomeTipoVino) {
        errors.NomeTipoVino = ['Campo obbligatorio'];
    } else if (values.NomeTipoVino.length < 2) {
        errors.NomeTipoVino = ['Nome troppo corto, non valido'];
    } else if (values.NomeTipoVino.length > 100) {
        errors.NomeTipoVino = ['Nome troppo lungo, max 100 caratteri'];
    } if (!values.OrdineVisualizzazione) {
        errors.OrdineVisualizzazione = ['Campo obbligatorio'];
    }
    return errors
};

//EDIT TIPO
export const EditTipiVino = props => (
    <Edit title="Modifica Tipo"{...props} undoable={false}>
        <SimpleForm submitOnEnter={true} validate={ValidateEditTipoVino} redirect="list">
            <TextInput
                source="NomeTipoVino"
                label="Nome tipo vino"
                resettable
                required={true}
            />
            <NumberInput
                required={true}
                label="Ordinamento APP"
                source="OrdineVisualizzazione"
                inputProps={{
                    step: 1,
                    min: 0
                }}
            />
        </SimpleForm>
    </Edit>
);
//VALIDATE CREATE TIPO
const ValidateCreateTipoVino = (values) => {
    const errors = {};
    if (!values.NomeTipoVino) {
        errors.NomeTipoVino = ['Campo obbligatorio'];
    } else if (values.NomeTipoVino.length < 2) {
        errors.NomeTipoVino = ['Nome troppo corto, non valido'];
    } else if (values.NomeTipoVino.length > 100) {
        errors.NomeTipoVino = ['Nome troppo lungo, max 100 caratteri'];
    } if (!values.OrdineVisualizzazione) {
        errors.OrdineVisualizzazione = ['Campo obbligatorio'];
    }
    return errors
};

//CREATE TIPO
export const CreateListaTipiVino = props => (
    <Create title="Aggiungi Tipo" {...props} >
        <SimpleForm submitOnEnter={true} redirect="list" validate={ValidateCreateTipoVino} undoable={false}>
            <TextInput
                source="NomeTipoVino"
                label="Nome tipo vino"                
                resettable
                required={true}
            />
            <NumberInput
                required={true}
                label="Ordinamento APP"
                source="OrdineVisualizzazione"
                inputProps={{
                    step: 1,
                    min: 0
                }}
            />
        </SimpleForm>
    </Create>
);

export default TipiVino;

