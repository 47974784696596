import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useGetList, Loading, Error } from "react-admin";

const payload = {
  pagination: { page: 1, perPage: 10 },
  sort: { field: "Mese", order: "ASC" },
};
export const VenditeTotaliUltimi3Mesi = (props) => {
  const {
    data: vini,
    loading,
    error,
  } = useGetList("VenditeTotaliUltimi3Mesi", {
    payload,
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (!vini) {
    return null;
  }
  return (
    <div>
      <h4>Totale vendite ultimi 3 mesi</h4>
      <div
        style={{
          
          textAlign: "right",
          borderRadius: 3,
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mese</TableCell>
              <TableCell>Tot Bottiglie</TableCell>
              <TableCell>Tot Costo</TableCell>
              <TableCell>Tot Prezzo</TableCell>
              <TableCell>Tot Margine</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vini.map((row) => (
              <TableRow key={row.Mese}>
                <TableCell component="th" scope="row">
                  {row.Mese}
                </TableCell>
                <TableCell align="left">{row.TotaleBottiglie}</TableCell>
                <TableCell align="left">
                  {row.TotaleCosto.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell align="left">
                  {row.TotalePrezzo.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell align="left">
                  {row.TotaleMargine.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
    // <React.Fragment>
    //   <h4>Totale vendite ultimi 3 mesi</h4>
    //   <Paper>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>Mese</TableCell>
    //           <TableCell>Tot Bottiglie</TableCell>
    //           <TableCell>Tot Costo</TableCell>
    //           <TableCell>Tot Prezzo</TableCell>
    //           <TableCell>Tot Margine</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {vini.map((row) => (
    //           <TableRow key={row.Mese}>
    //             <TableCell component="th" scope="row">
    //               {row.Mese}
    //             </TableCell>
    //             <TableCell align="center">{row.TotaleBottiglie}</TableCell>
    //             <TableCell align="center">
    //               {row.TotaleCosto.toLocaleString("it-IT", {
    //                 minimumFractionDigits: 2,
    //                 style: "currency",
    //                 currency: "EUR",
    //               })}
    //             </TableCell>
    //             <TableCell align="center">
    //               {row.TotalePrezzo.toLocaleString("it-IT", {
    //                 minimumFractionDigits: 2,
    //                 style: "currency",
    //                 currency: "EUR",
    //               })}
    //             </TableCell>
    //             <TableCell align="center">
    //               {row.TotaleMargine.toLocaleString("it-IT", {
    //                 minimumFractionDigits: 2,
    //                 style: "currency",
    //                 currency: "EUR",
    //               })}
    //             </TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </Paper>
    // </React.Fragment>
  );
};

// <Query type="getList" resource="VenditeTotaliUltimi3Mesi" payload={payload}  >
//     {({ data, loading, error }) => {
//         if (loading) { return <Loading />; }
//         if (error) { return <Error />; }
//         return (
//             <React.Fragment>
//             <h4>Totale vendite ultimi 3 mesi</h4>
//             <Paper>

//                 <Table >
//                     <TableHead>
//                         <TableRow>
//                             <TableCell>Mese</TableCell>
//                             <TableCell>Tot Bottiglie</TableCell>
//                             <TableCell>Tot Costo</TableCell>
//                             <TableCell>Tot Prezzo</TableCell>
//                             <TableCell>Tot Margine</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {data.map(row => (
//                             <TableRow key={row.Mese}>
//                                 <TableCell component="th" scope="row">{row.Mese}</TableCell>
//                                 <TableCell align="center">{row.TotaleBottiglie}</TableCell>
//                                 <TableCell align="center">{row.TotaleCosto.toLocaleString('it-IT', { minimumFractionDigits: 2, style: 'currency', currency: 'EUR'})}</TableCell>
//                                 <TableCell align="center">{row.TotalePrezzo.toLocaleString('it-IT', { minimumFractionDigits: 2, style: 'currency', currency: 'EUR' })}</TableCell>
//                                 <TableCell align="center">{row.TotaleMargine.toLocaleString('it-IT', { minimumFractionDigits: 2, style: 'currency', currency: 'EUR' })}</TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//                 </Paper>
//             </React.Fragment>
//         );
//     }}
// </Query>
