import React from 'react';
import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
//import MyMenu from './MyMenu';
//import MyNotification from './MyNotification';

const MyLayout = (props) => <Layout
    {...props}
    appBar={MyAppBar}    
/>;

export default MyLayout;