import React, { Fragment } from 'react';
import { VenditeTotaliUltimi3Mesi } from './VenditeTotaliUltimi3Mesi';
import {
    List, Datagrid, TextField, 
    DateInput, NumberField ,
    Filter,
    downloadCSV
} from 'react-admin';
import jsonExport from 'jsonexport/dist';


//FILTRI
const VenditePeriodoFilters = props => (
    <Filter {...props}>
        <DateInput name="DataDa" source="DataDa" alwaysOn />
        <DateInput name="DataA" source="DataA" alwaysOn />
    </Filter>
); 

const exporter = (records) => {
        const data = records.map(record => ({            
            Vino : record.NomeVino,
            TotaleBottiglie : record.TotaleBottiglie,
            TotaleCosto : record.TotaleCosto.toString().replace('.',','),
            TotalePrezzo : record.TotalePrezzo.toString().replace('.',','),
            TotaleMargine : record.TotaleMargine.toString().replace('.',','),

        }));
        jsonExport(data, {
            headers: ['Vino', 'TotaleBottiglie', 'TotaleCosto', 'TotalePrezzo', 'TotaleMargine'],
            rowDelimiter: ';'
        }, (err, csv) => {;
            const BOM = '\uFEFF'
            downloadCSV(`${BOM} ${csv}`, 'Vendite Per Periodo');
        });

};

//NELLA VENDITA CALICE MODIFICARE RIFERIMENTO TRUE/FALSE A CALICE 
//LIST VINI ORDINI
export const VenditePerPeriodo = props => (
    <Fragment>
       
        <VenditeTotaliUltimi3Mesi  {...props}/>
        <h4>Vendite</h4>
        <List
            title="Vendite per periodo"
            filters={<VenditePeriodoFilters />}
            bulkActionButtons={false}
            exporter={exporter}
            {...props}
        >
            <Datagrid >
                <TextField source="id" label="#" />
                <TextField source="NomeVino" label="Vino" />
                <NumberField source="TotaleBottiglie" label="Tot Bottiglie" />
                <NumberField source="TotaleCosto" label="Tot Costo" options={{
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR' }}/>
                <NumberField source="TotalePrezzo" label="Tot Prezzo" options={{
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR'}}/>
                <NumberField source="TotaleMargine" label="Tot Margine" options={{
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR' }}/>
            </Datagrid>
        </List>
    </Fragment>
);
        



