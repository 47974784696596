import React from 'react';
import {
    List, Datagrid, TextField, 
    EditButton, Edit, SimpleForm, TextInput,
    ReferenceInput, Create,
    Filter, AutocompleteInput, ReferenceField
} from 'react-admin';

//FILTRO
const RegioneFilter = props => (
    <Filter {...props}>
        <TextInput
            name="search"
            label="Cerca regione"
            source="q"
            resettable
            alwaysOn />
    </Filter>
);


//LIST REGIONI
export const Regioni = props => (
    <List 
        title="Lista delle regioni"
        filters={<RegioneFilter />} {...props}
        sort={{ field: 'NomeRegione', order: 'ASC' }}
        perPage={25}        
    >
        <Datagrid rowClick="edit">
            <TextField source="NomeRegione" label="Regione" />
            <TextField label="Regione EN" source="Regione_EN"/>
            <TextField label="Regione FR" source="Regione_FR" />
            
            <ReferenceField label="Stato" source="IdStato" reference="Stati">
                <TextField source="NomeStato" />
            </ReferenceField>
            
            <EditButton />
        </Datagrid>
    </List>
);

//VALIDATE EDIT REGIONE
const ValidateEditRegione = (values) => {
    const errors = {};
    if (!values.NomeRegione) {
        errors.NomeRegione = ['Campo obbligatorio'];
    } else if (values.NomeRegione < 3) {
        errors.NomeRegione = ['Nome troppo corto, inserire almeno 3 caratteri'];
    } else if (values.NomeRegione > 50) {
        errors.NomeRegione = ['Nome troppo lungo, max 50 caratteri'];
    } if (!values.IdStato) {
        errors.IdStato = ['Campo obbligatorio'];
    } 
    return errors
};

//EDIT REGIONE
export const EditRegione = props => (
    <Edit title="Modifica Regione"{...props} undoable={false}>
        <SimpleForm
            submitOnEnter={true}
            redirect="list"
            validate={ValidateEditRegione}
        >
            <ReferenceInput
                source="IdStato"
                label="Stato"
                reference="Stati"
                required={true}
                sort={{ field: "NomeStato", order: "ASC" }}
                
            >
                <AutocompleteInput optionText="NomeStato" />

            </ReferenceInput>
            <TextInput source="NomeRegione" label="Regione" required={true} />

            <TextInput source="Regione_EN" label="Regione EN" resettable />
            <TextInput source="Regione_FR" label="Regione FR" resettable />
        </SimpleForm>
    </Edit>
);
//VALIDATE CREATE REGIONEE
const ValidateCreateRegione = (values) => {
    const errors = {};
    if (!values.NomeRegione) {
        errors.NomeRegione = ['Campo obbligatorio'];
    } else if (values.NomeRegione < 3) {
        errors.NomeRegione = ['Nome troppo corto, inserire almeno 3 caratteri'];
    } else if (values.NomeRegione > 50) {
        errors.NomeRegione = ['Nome troppo lungo, max 50 caratteri'];
    } if (!values.IdStato) {
        errors.IdStato = ['Campo obbligatorio'];
    }
    return errors
};


export const CreateRegione = props => (
    <Create title="Aggiungi Regione" {...props} undoable={false}>
        <SimpleForm
            submitOnEnter={true}
            redirect="list"
            validate={ValidateCreateRegione}
        >
            <ReferenceInput
                source="IdStato"
                label="Stato"
                reference="Stati"
                required={true}
                sort={{ field: "NomeStato", order: "ASC" }}
            >
                <AutocompleteInput optionText="NomeStato" />

            </ReferenceInput>
            <TextInput source="NomeRegione" label="Regione" required={true}/>
            
            <TextInput source="Regione_EN" label="Regione EN" resettable />
            <TextInput source="Regione_FR" label="Regione FR" resettable />
        </SimpleForm>
    </Create>
);

export default Regioni;

