import React from 'react';
import {
    List, Datagrid, TextField, 
    EditButton, Edit, SimpleForm,TextInput,
    Create, Filter
} from 'react-admin';

//FILTRO
const StatoFilter = props => (
    <Filter {...props}>
        <TextInput
            name="search"
            label="Cerca stato"
            source="q"
            resettable
            alwaysOn />
    </Filter>
);

//LIST STATI
export const Stati = props => (
    <List
        title="Lista degli stati"
        filters={<StatoFilter />} {...props}
        sort={{ field: 'NomeStato', order: 'ASC' }}
        perPage={25}
    >
        <Datagrid rowClick="edit">
            <TextField source="NomeStato" label="Stato" />
            <TextField label = "Stato EN" source="Stato_EN"/>
            <TextField label="Stato FR" source="Stato_FR"/>
            <EditButton />
        </Datagrid>
    </List>
);
//VALIDATE EDIT STATO
const ValidateEditStato = (values) => {
    const errors = {};
    if (!values.NomeStato) {
        errors.NomeStato = ['Campo obbligatorio'];
    } else if (values.NomeStato.length < 2) {
        errors.NomeStato = ['Nome troppo corto, non valido'];
    } else if (values.NomeStato.length > 50) {
        errors.NomeStato = ['Nome troppo lungo, max 50 caratteri'];
    }
    return errors
};


//EDIT STATO
export const EditStato = props => (
    <Edit title="Modifica Stato"{...props} undoable={false}>
        <SimpleForm submitOnEnter={true} redirect="list" validate={ValidateEditStato}>
            <TextInput source="NomeStato" label="Stato" resettable required={true} />
            <TextInput source="Stato_EN" label="Stato EN" resettable />
            <TextInput source="Stato_FR" label="Stato FR" resettable />
        </SimpleForm>
        
    </Edit>
);
//VALIDATE CREATE STATO
const ValidateCreateStato = (values) => {
    const errors = {};
    if (!values.NomeStato) {
        errors.NomeStato = ['Campo obbligatorio'];
    } else if (values.NomeStato.length < 2) {
        errors.NomeStato = ['Nome troppo corto, non valido'];
    } else if (values.NomeStato.length > 50) {
        errors.NomeStato = ['Nome troppo lungo, max 50 caratteri'];
    }
    return errors
};
//CREATE STATO 
export const CreateStato = props => (
    <Create title="Aggiungi Stato" {...props} undoable={false}>
        <SimpleForm submitOnEnter={true} redirect="list" validate={ValidateCreateStato}>
            <TextInput source="NomeStato" label="Stato" resettable required={true} />
            <TextInput source="Stato_EN" label="Stato EN" resettable />
            <TextInput source="Stato_FR" label="Stato FR" resettable />
        </SimpleForm>
        
    </Create>
);

export default Stati;

