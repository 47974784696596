import React from 'react';
import {
    List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput,
    Create, Filter, ReferenceInput, AutocompleteInput, ImageInput, ImageField
} from 'react-admin';

//FILTRO
const ProduttoriFilter = props => (
    <Filter {...props}>
        <TextInput
            name="search"
            label="Cerca produttore"
            source="q"
            resettable
            alwaysOn />
    </Filter>
);
//LIST PRODUTTORI
export const Produttori = props => (
    <List
        title="Lista dei produttori"
        filters={<ProduttoriFilter />} {...props}
        sort={{ field: 'NomeProduttore', order: 'ASC' }}
        perPage={25}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="NomeProduttore"
                label="Produttore"                
            />
            <TextField
                source="NomeStato"
                label="Stato"                
            />
            <TextField
                source="NomeRegione"
                label="Regione"                
            />
            <TextField
                source="Luogo"
                label="Territorio"                
            />
            <TextField
                source="Descrizione"
                label="Descrizione"                
            />
            <EditButton />
        </Datagrid>
    </List>
);

const ValidateEditProduttore = (values) => {
    const errors = {};
    if (!values.NomeProduttore) {
        errors.NomeProduttore = ['Campo obbligatorio'];
    } else if (values.NomeProduttore.length < 2) {
        errors.NomeProduttore = ['Nome troppo corto, non valido'];
    } else if (values.NomeProduttore.length > 50) {
        errors.NomeProduttore=['Massimo 50 caratteri']
    }
    return errors
};

const ValidateCreateProduttore = (values) => {
    const errors = {};
    if (!values.NomeProduttore) {
        errors.NomeProduttore = ['Campo obbligatorio'];
    } else if (values.NomeProduttore.length < 2) {
        errors.NomeProduttore = ['Nome troppo corto, non valido'];
    } else if (values.NomeProduttore.length > 50) {
        errors.NomeProduttore = ['Massimo 50 caratteri']
    }
    return errors
};

export const EditProduttore = props => (
    <Edit title="Modifica Produttore"{...props}>
        <SimpleForm submitOnEnter={true} validate={ValidateEditProduttore} undoable={false}>
            <TextInput
                source="NomeProduttore"
                label="Nome"
                required={true}
                helperText="Max 50 caratteri"
                resettable />
            <ReferenceInput
                    
                    source="IdRegione"
                    label="Territorio"
                    reference="Regioni"
                    //validate={validateMandatory}
                    sort={{ field: "NomeCompletoRegione", order: "ASC" }}
                    perPage={500}
                >
                    <AutocompleteInput optionText="NomeCompletoRegione" source="NomeCompletoRegione" reference="Regioni" fullWidth/>
                </ReferenceInput>
                <TextInput
                source="Luogo"
                label="Territorio"
                helperText="Max 50 caratteri"
                resettable />
                <TextInput
                        fullWidth
                        source="Descrizione"
                        label="Descrizione"
                        resettable
                        multiline
                    />
                <ImageField source="ImageBase64" label="Immagine attuale"/>
                <ImageInput source="pictures" label="Immagine rappresentativa" accept="image/*">
                    <ImageField source="ImageBase64" title="title" />
                </ImageInput>

        </SimpleForm>
    </Edit>
);

export const CreateProduttore = props => (
    <Create title="Aggiungi Produttore" {...props}>
        <SimpleForm submitOnEnter={true} redirect="list" validate={ValidateCreateProduttore} undoable={false}>
            <TextInput
                source="NomeProduttore"
                label="Nome"
                required={true}
                helperText="Max 50 caratteri"
                resettable />
            <ReferenceInput
                    
                    source="IdRegione"
                    label="Territorio"
                    reference="Regioni"
                    //validate={validateMandatory}
                    sort={{ field: "NomeCompletoRegione", order: "ASC" }}
                    perPage={500}
                >
                    <AutocompleteInput optionText="NomeCompletoRegione" source="NomeCompletoRegione" reference="Regioni" fullWidth/>
                </ReferenceInput>
                <TextInput
                source="Luogo"
                label="Territorio"
                helperText="Max 50 caratteri"
                resettable />
                <TextInput
                        fullWidth
                        source="Descrizione"
                        label="Descrizione"
                        resettable
                        multiline
                    />


        </SimpleForm>
    </Create>
);

export default Produttori;

