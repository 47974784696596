import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { API_URL } from './globalConst';

//PER AUTENTICAZIONE
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', 'Bearer '+token);

    //console.log(options.headers);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(API_URL, httpClient);

const myDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        if ( (resource !== 'Produttori' && resource !== 'Vini') || !params.data.pictures) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        /**
         * For Produttori update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */
        
        // Freshly dropped pictures are File objects and must be converted to base64 strings
        
        //console.log(params.data.pictures);
        const myPromise = new Promise((resolve, reject) => {
           
            resolve(convertFileToBase64(params.data.pictures));
           
          });

        return myPromise
            .then(base64Pictures => dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    /*picture:{
                        data: base64Pictures.data,
                        base64: base64Pictures
                    }*/
                    imageData: base64Pictures,
                    imageFilename: params.data.pictures.title
                },
            }));
        
        

        //const newPictures = params.data.pictures.rawFile instanceof File;
        /*const newPictures = params.data.pictures.filter(
            p => p.rawFile instanceof File
        );
        const formerPictures = params.data.pictures.filter(
            p => !(p.rawFile instanceof File)
        );

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64,
                    title: `${params.data.title}`,
                }))
            )
            .then(transformedNewPictures =>
                dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        pictures: [
                            ...transformedNewPictures,
                            //...formerPictures,
                        ],
                    },
                })
            );*/
    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export default myDataProvider;