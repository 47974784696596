import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  NumberField,
  BooleanInput,
  ReferenceInput,
  Create,
  Filter,
  AutocompleteInput,
  ReferenceField,
  downloadCSV,
  useGetRecordId,
  useRedirect,
  useCreate,
  useRefresh,
} from "react-admin";
import { useLocation } from "react-router-dom";
import jsonExport from "jsonexport/dist";
//FILTRO
const MenuFilter = (props) => (
  <Filter {...props}>
    <TextInput
      name="search"
      label="Cerca Menu"
      source="q"
      resettable
      alwaysOn
    />
  </Filter>
);

const exporter = (records, fetchRelatedRecords) => {
  // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
  console.log("Before fetch");
  fetchRelatedRecords(records, "IdMenuTitolo", "MenuTitolo").then((titoli) => {
    const data = records.map((record) => ({
      IdMenu: record.IdMenu,
      Titolo: titoli[record.IdMenuTitolo].Titolo,
      SottoTitolo: record.SottoTitolo,
      Descrizione: record.Descrizione,
      Prezzo: record.Prezzo.toString().replace(".", ","),
      Ordinamento: record.Ordinamento,
      Visibile: record.Visibile,
    }));
    jsonExport(
      data,
      {
        headers: [
          "IdMenu",
          "Titolo",
          "SottoTitolo",
          "Descrizione",
          "Prezzo",
          "Ordinamento",
          "Visibile",
        ],
        rowDelimiter: ";",
      },
      (err, csv) => {
        const BOM = "\uFEFF";
        downloadCSV(`${BOM} ${csv}`, "Menu");
      }
    );
  });
};

//LIST REGIONI
export const Menu = (props) => (
  <List
    title="Menu"
    filters={<MenuFilter />}
    {...props}
    sort={{ field: "Ordinamento", order: "ASC" }}
    perPage={25}
    exporter={exporter}
  >
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Titolo"
        source="IdMenuTitolo"
        reference="MenuTitolo"
      >
        <TextField source="Titolo" />
      </ReferenceField>
      <TextField source="SottoTitolo" label="Voci" />
      <TextField source="Descrizione" label="Descrizione" />
      <NumberField
        source="Prezzo"
        label="Prezzo"
        options={{
          maximumFractionDigits: 2,
          style: "currency",
          currency: "EUR",
        }}
      />
      <TextField source="Ordinamento" label="Ordinamento" />
      <BooleanField source="Visibile" label="Visibile" />
      <EditButton />
    </Datagrid>
  </List>
);

//VALIDATE EDIT REGIONE
const ValidateMenu = (values) => {
  const errors = {};
  if (!values.SottoTitolo) {
    errors.SottoTitolo = ["Campo obbligatorio"];
  }
  if (!values.Prezzo) {
    errors.Prezzo = ["Campo obbligatorio"];
  }

  return errors;
};

//EDIT REGIONE
export const EditMenu = (props) => {
  const IdMenuTitolo = useGetRecordId();
  console.log("IdMenuTitolo", IdMenuTitolo);
  // const redirect = IdMenuTitolo ? `/MenuTitolo/${IdMenuTitolo}/Menu` : false;

  return (
    <Edit title="Modifica Menu" {...props} undoable={false}>
      <SimpleForm
        submitOnEnter={true}
        // initialValues={{ IdMenuTitolo }}
        validate={ValidateMenu}
      >
        <ReferenceInput
          source="IdMenuTitolo"
          label="Titolo"
          reference="MenuTitolo"
          required={true}
          sort={{ field: "Titolo", order: "ASC" }}
        >
          <AutocompleteInput optionText="Titolo" />
        </ReferenceInput>

        <TextInput source="SottoTitolo" label="Voci" required={true} />
        <TextInput source="Descrizione" label="Descrizione" multiline />
        <NumberInput source="Prezzo" label="Prezzo" required={true} />
        <NumberInput source="Ordinamento" label="Ordinamento" />
        <BooleanInput source="Visibile" label="Visibile" />
      </SimpleForm>
    </Edit>
  );
};

export const CreateMenu = (props) => {
  const location = useLocation()
  const IdMenuTitolo = location.search.split("=").pop()


  return (
    <Create title="Aggiungi menu" {...props} undoable={false}>
      <SimpleForm
        submitOnEnter={true}
        defaultValues={ {IdMenuTitolo} }
        validate={ValidateMenu}
        sanitizeEmptyValues
      >
        <ReferenceInput
          source="IdMenuTitolo"
          label="Titolo"
          reference="MenuTitolo"
          sort={{ field: "Titolo", order: "ASC" }}
        >
          <AutocompleteInput optionText="Titolo" />
        </ReferenceInput>

        <TextInput source="SottoTitolo" label="Titolo Voce" required={true} />
        <TextInput source="Descrizione" label="Descrizione" multiline />
        <NumberInput source="Prezzo" label="Prezzo" required={true} />
        <NumberInput source="Ordinamento" label="Ordinamento" />
        <BooleanInput source="Visibile" label="Visibile" />
      </SimpleForm>
    </Create>
  );
};

export default Menu;
