import React from "react";
import { AppBar, Toolbar } from "react-admin";
import Typography from "@material-ui/core/Typography";
//import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} >
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        <img src="./logo-vini.png" alt="Carta dei vini" />
        <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
