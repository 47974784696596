import React from 'react';
import {
    List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, 
    Create, Filter
} from 'react-admin';

//FILTRO
const VitignoFilter = props => (
    <Filter {...props}>
        <TextInput
            name="search"
            label="Cerca vitigno"
            source="q"
            resettable
            alwaysOn />
    </Filter>
);

//LIST VITIGNI
export const Vitigni = props => (
    <List
        title="Lista dei vitigni"
        filters={<VitignoFilter />} {...props}
        sort={{ field: 'NomeVitigno', order: 'ASC' }}
        perPage={25}
    >
        <Datagrid rowClick="edit"  >
            <TextField source="NomeVitigno" label="Vitigno" />            
            <EditButton />
        </Datagrid>
    </List>
);

//VALIDATE EDIT VITIGNO
const ValidateEditVitigno = (values) => {
    const errors = {};
    if (!values.NomeVitigno) {
        errors.NomeVitigno = ['Campo obbligatorio'];
    } else if (values.NomeVitigno.length < 2) {
        errors.NomeVitigno = ['Nome troppo corto, non valido'];
    } else if (values.NomeVitigno.length > 50) {
        errors.NomeVitigno = ['Nome troppo lungo, max 50 caratteri'];
    }
    return errors
};
//EDIT VITIGNO
export const EditVitigno = props => (
    <Edit title="Modifica Vitigno"{...props} undoable={false}>
        <SimpleForm submitOnEnter={true} validate={ValidateEditVitigno} redirect="list">
            <TextInput
                source="NomeVitigno"
                label="Vitigno"
                resettable
                required={true}
            />
        </SimpleForm>
    </Edit>
);
//VALIDATE CREATE VITIGNO
const ValidateCreateVitigno = (values) => {
    const errors = {};
    if (!values.NomeVitigno) {
        errors.NomeVitigno = ['Campo obbligatorio'];
    } else if (values.NomeVitigno.length < 2) {
        errors.NomeVitigno = ['Nome troppo corto, non valido'];
    } else if (values.NomeVitigno.length > 50) {
        errors.NomeVitigno = ['Nome troppo lungo, max 50 caratteri'];
    }
    return errors
};

export const CreateVitigno = props => (
    <Create title="Aggiungi Vitigno" {...props} undoable={false}>
        <SimpleForm submitOnEnter={true} redirect="list" validate={ValidateCreateVitigno} >
            <TextInput
                source="NomeVitigno"
                label="Vitigno"
                resettable
                required={true}
            />
        </SimpleForm>
    </Create>
);

export default Vitigni;



