import React from 'react';
import {
    List, Datagrid, TextField, 
    EditButton, Edit, SimpleForm, TextInput, BooleanInput, BooleanField,
    Create,
} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';
import { withStyles } from '@material-ui/core/styles';
//LIST REGIONI
export const MenuCamatilde = props => (
    <List 
        title="Menu"
        actions={null}
        {...props}        
    >
        <Datagrid rowClick="edit">
            <TextField source="Titolo" />
            <BooleanField source="Visibile"  />
            <EditButton />
        </Datagrid>
    </List>
);

//VALIDATE EDIT REGIONE
const ValidateMenuCamtilde = (values) => {
    const errors = {};
    if (!values.Titolo) {
        errors.SottoTitolo = ['Campo obbligatorio'];
    }
    if (!values.Html) {
        errors.Html = ['Campo obbligatorio'];
    }

    return errors
};


const toolbarOptions = [
    [{'font':['', 'serif', 'monospace']}],        // 
    ['bold', 'italic', 'underline'],        // toggled buttons
    //['blockquote', 'code-block'],  
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    //[{ 'direction': 'rtl' }],                         // text direction  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6] }],  
    //[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //[{ 'font': [] }],
    [{ 'align': ['', 'center', 'right', 'justify'] }],  
    //['clean']                                         // remove formatting button
  ];

const styles = {
    editor: {
        height: 300,
        width: '100%'
    },
};

//EDIT REGIONE
export const EditMenuCamatilde =  withStyles(styles) (({ classes, ...props }) => (  
    <Edit title="Modifica Menu"{...props} undoable={false}>
        <SimpleForm
            submitOnEnter={true}
            validate={ValidateMenuCamtilde}
        >
            <BooleanInput source="Visibile"/>
            <TextInput source="Titolo" label="Titolo" required={true} />
            <RichTextInput source="Html" label="Contenuto"  toolbar={toolbarOptions} className={classes.editor}  required={true}/>
           
        </SimpleForm>
    </Edit>
));


export const CreateMenuCamatilde =  withStyles(styles) (({ classes, ...props }) => (  
    <Create title="Aggiungi menu" {...props} undoable={false}>
        <SimpleForm
            submitOnEnter={true}
            validate={ValidateMenuCamtilde}
        >
            <BooleanInput source="Visibile"/>
            <TextInput source="Titolo" label="Titolo" required={true}  />            
            <RichTextInput source="Html" label="Contenuto"  toolbar={toolbarOptions} className={classes.editor}  required={true}/>
            
        </SimpleForm>
    </Create>

));

export default MenuCamatilde;

