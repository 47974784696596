import React, { Fragment } from 'react';
import {
    List, Datagrid, TextField, SimpleForm, TextInput,
    ReferenceInput, Create, DateInput, DateField, NumberInput,
    Filter, NumberField, ReferenceField, BooleanField, BooleanInput, AutocompleteInput, FormDataConsumer,
    downloadCSV
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

//FILTRI
const OrdineFilter = props => (
    <Filter {...props}>
        <TextInput
            name="search"
            label="Cerca ordine"
            source="q"
            resettable
            alwaysOn />
    </Filter>
);

const exporter = (records) => {

    const data = records.map(record => ({            
        IdVinoOrdine : record.IdVinoOrdine,
        NomeVino : record.NomeVino,
        DataOrdine : record.DataOrdine,
        Qta : record.Qta,
        Prezzo : record.Prezzo.toString().replace('.',','),
        Totale : record.Totale.toString().replace('.',','),
        Calice : record.Calice ? 'X' : ''

    }));
    jsonExport(data, {
        headers: ['IdVinoOrdine', 'NomeVino', 'DataOrdine', 'Qta', 'Prezzo', 'Totale', 'Calice'],
        rowDelimiter: ';'
    }, (err, csv) => {;
        const BOM = '\uFEFF'
        downloadCSV(`${BOM} ${csv}`, 'Vendite Per Periodo');
    });

};

//NELLA VENDITA CALICE MODIFICARE RIFERIMENTO TRUE/FALSE A CALICE 
//LIST VINI ORDINI
export const ViniOrdini = props => (
    
        <List
            title="Lista degli ordini"
            filters={<OrdineFilter />} {...props}
            sort={{ field: 'DataOrdine', order: 'DESC' }}
            exporter={exporter}
        >
            <Datagrid rowClick="edit" >
                <TextField source="IdVinoOrdine" label="Ordine" />
                
                <ReferenceField label="Vino" source="IdVino" reference="Vini" sortable={false}>
                        <TextField source="NomeCompleto" />
                    </ReferenceField>
                <TextField source="NomeProduttore" label="Produttore"/>
                <DateField source="DataOrdine" label="Data " />
                <NumberField source="Qta" label="Quantita'" />
                <NumberField source="Prezzo" label="Prezzo" options={{
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR'
                }}/>
                <NumberField source="Totale" label="Totale" options={{
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR'
                }}/>
                <BooleanField
                    source="Calice"
                    label="Venduto a calice"
                    valueLabelTrue="Si"
                    valueLabelFalse="No"
                />
            </Datagrid>
        </List>
    
);


//const validateTipo=choices(['{idTipoVino}'], 'Campo obbligatorio')
const ValidateCreateVinoOrdine = (values) => {
    const errors = {};
    if (!values.IdVino) {
        errors.IdVino = ['Campo obbligatorio'];
    } /*else if (values.NomeVino.length < 2) {
        errors.NomeVino = ['Nome troppo corto, non valido'];
    } else if (values.NomeVino.length > 100) {
        errors.NomeVino = ['Nome troppo lungo, max 100 caratteri'];
    }*/
    return errors
};

const inputVinoFormatter = v => {
    return v.NomeCompleto + ', ' + v.NomeProduttore;
  };
  

//CREATE ORDINE
export const CreateVinoOrdine = props => (
    <Create title="Aggiungi Ordine" {...props} undoable={false}>
        <SimpleForm submitOnEnter={true} redirect="list" validate={ValidateCreateVinoOrdine} >
            <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => (
                    <Fragment>
                        <ReferenceInput
                            source="IdVino"
                            label="Vino"                
                            reference="Vini"
                            sort={{ field: "VinoEProduttore", order: "ASC" }}
                            helpText="Scegliere un vino già presente"
                        
                        >
                            <AutocompleteInput
                                optionText={inputVinoFormatter}
                                required={true}
                            />

                        </ReferenceInput>
                    </Fragment>
                )}
            </FormDataConsumer>
            <DateInput
                source="DataOrdine"
                label="Data Ordine"
                options={{ format: 'DD/MM/YYYY' }}
                required={true}
                defaultValue={new Date()}                
            />            
            <NumberInput
                label="Quantita'"
                source="Qta"
                inputProps={{
                    step: 1,
                    min: 1,
                    max: 1000,
                    placeholder: "bottiglie"
                }}
                required={true}
                defaultValue={1}
            />
            <BooleanInput source="Calice" label="Vendita calice" />
                        
                    
        </SimpleForm>
    </Create>
);

export default ViniOrdini;



